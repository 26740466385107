import React, {useCallback, useEffect, useState} from 'react'
import {
  DirectionsRenderer,
  DirectionsService,
  GoogleMap,
  Marker,
  StreetViewPanorama,
  useJsApiLoader
} from '@react-google-maps/api';

const containerStyle = {
  width: '400px',
  maxWidth: '100%',
  height: '400px',
};

const GoogleMapContainer = ({lat, lng, heading, pitch, marker, streetView, direction, destination, origin, travelMode}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY
  })

  const [map, setMap] = useState(null)
  const [track, setTrack] = useState(null)

  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [])

  function directionsCallback (response) {
    //console.log(response)
    if (response !== null && !track) {
      if (response.status === 'OK') {
        setTrack(response)
      } else {
        //console.log('response: ', response)
      }
    }
  }

  useEffect(() => {
    //console.log(map?.center?.lat())
  },[map])

  const onUnmount = useCallback(function callback(map) {
    //setMap(null)
  }, [])

  return isLoaded ? (
      <GoogleMap
          mapContainerStyle={containerStyle}
          center={{
            lat: lat,
            lng: lng
          }}
          zoom={16}
          onLoad={onLoad}
          onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        {marker &&
          <Marker
              onLoad={onLoad}
              position={{
                lat: lat,
                lng: lng
              }}
          />
        }
        {streetView &&
          <StreetViewPanorama
              position={{
                lat: lat,
                lng: lng
              }}
              visible={true}
              pov={{
                heading: heading ? heading : 0,
                pitch: pitch ? pitch : 0,
              }}
          />
        }
        {direction &&
            <>
              {destination && origin &&
                <DirectionsService
                    options={{
                      destination: {
                        lat: destination?.lat,
                        lng: destination?.lng
                      },
                      origin: {
                        lat: origin?.lat,
                        lng: origin?.lng
                      },
                      travelMode: travelMode ? travelMode : 'DRIVING'
                    }}
                    callback={directionsCallback}
                    onLoad={directionsService => {
                      //console.log('DirectionsService onLoad directionsService: ', directionsService)
                    }}
                    // optional
                    onUnmount={directionsService => {
                      //console.log('DirectionsService onUnmount directionsService: ', directionsService)
                    }}
                />
              }
              {track &&
                <DirectionsRenderer
                    // required
                    options={{
                      directions: track
                    }}
                    // optional
                    onLoad={directionsRenderer => {
                      //console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                    }}
                    // optional
                    onUnmount={directionsRenderer => {
                      //console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                    }}
                />
              }
            </>
        }
      </GoogleMap>
  ) : <></>
}

export default React.memo(GoogleMapContainer)