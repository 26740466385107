import {Avatar, IconButton, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {LocalPhone} from "@mui/icons-material";
import React from "react";

export const contact = [
  {
    type:"entertainer",
    name:"Yoann",
    lastname:"Jaccard",
    number:"+41787088281",
    email:"",
  },
  {
    type:"entertainer",
    name:"Alexandra",
    lastname:"De Rahm",
    number:"+41786457424",
    email:"",
  },
  {
    type:"witness",
    name:"Adrien",
    lastname:"Oberhänsli",
    number:"+41799613790",
    email:"",
  },
  {
    type:"witness",
    name:"Aline",
    lastname:"Burnand",
    number:"+41792628381",
    email:"",
  },
]

export const ContactCard = ({person}) => {
  return (
      <ListItem
          alignItems="flex-start"
          secondaryAction={
            <IconButton edge="end" aria-label="comments" role={"link"}>
              <LocalPhone/>
            </IconButton>
          }
      >
        <ListItemAvatar>
          <Avatar alt={person.name}>
            {person.name && person.name.charAt(0).toUpperCase()}
            {person.lastname && person.lastname.charAt(0).toUpperCase()}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
            primary={person.name}
            secondary={"indisponible"}
            secondaryTypographyProps={{color:"primary"}}
        />
      </ListItem>
  )
}