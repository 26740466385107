import styled from "styled-components";
import "../../global/main.css"

export const WeddingPageStyle = styled.div`
  background:  #ECE5E5FF;
  backdrop-filter: blur(65px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  & .wedding-title{
    font-family: var(--font-family-playfair_display);
  }
  & .timeline-hour{
    font-family: var(--font-family-playfair_display);
    font-size: 24px;
    line-height: 18px;
  }
`;

