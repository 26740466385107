import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {Box} from "@mui/material";
import React from "react";


const itemData = [
  {
    img: 'https://images.pexels.com/photos/5540721/pexels-photo-5540721.jpeg',
    title: 'Rural',
    source:"pexels",
    rows: 2,
    cols: 2,
  },
  {color:"#A9DAD1"},
  {color: '#88BBAA'},
  {color:"#F59598"},
  {color: '#FBDEDE'},
  {color:"#EFEFEF"},
  {color: '#E5E5E5'},
  {color:"#F4F0DB"},
  {color: '#ECD9BA'},
  {color:"#A6C7EA"},
  {color: '#689AAB'},
  {
    img: 'https://images.pexels.com/photos/305827/pexels-photo-305827.jpeg',
    title: 'Pastel',
    source:"pexels",
    rows: 2,
    cols: 2,
  },
  {color:"#CDB3D5"},
  {color: '#B3B4DA'},
];


export const ImageGrid = () => {

  return (
      <ImageList sx={{ width: "100%", minHeight: 100, overflow:"hidden", margin:0, justifyContent:"center"}} cols={4} rowHeight={100}>
        {itemData.map((item, index) => {
          return(
              <ImageListItem key={index} cols={item.cols || 1} rows={item.rows || 1}>
                {item.img &&
                    <img
                        src={`${item.img}${item.source === "pexels" ? "?fit=crop&fm=jpg&h=200&w=200": "?w=200&h=200&fit=crop&auto=format"}`}
                        srcSet={`${item.img}${item.source === "pexels" ? "?fit=crop&fm=jpg&h=400&w=400": "?w=200&h=200&fit=crop&auto=format&dpr=2 2x"}`}
                        alt={item.title}
                        loading="lazy"
                    />
                }
                {item.color &&
                    <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          minWidth: "100px",
                          minHeight: "100px",
                          backgroundColor: item.color,
                          '&:hover': {
                            //backgroundColor: 'primary.main',
                            opacity: [0.8, 0.7, 0.6],
                          },
                        }}
                    />
                }
              </ImageListItem>
          )
        })}
      </ImageList>
  );
}