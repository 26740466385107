import {Typography, useMediaQuery} from "@mui/material";
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from "@mui/lab";
import React from "react";


export const timeLineList = [
  {
    hour:"14h30",
    title:"Mariage Civil à La Maison Jaune de Cully",
    place:"Rte de Lausanne 1",
    address:"1096 Cully",
    icon:"",
  },
  {
    hour:"15h30",
    title:"Rafraîchissement et séance photo",
    place:"Place d'Armes à Cully",
    icon:"",
  },
  {
    hour:"16h00",
    title:"Départ du cortège",
    place:"Parking de Cully",
    icon:"",
  },
  {
    hour:"16h30 ",
    title:"Apéritif à l'Hôtel du Léman",
    place:"Ch. de la Fontaine 2",
    address:"1805 Jongny",
    icon:"",
  },
  {
    hour:"Dès 18h30",
    title:"Repas de mariage",
    place:"Salle du restaurant",
    icon:"",
  },
  {
    hour:"Dès 22h",
    title:"Soirée dansante",
    place:"Carnotzet",
    icon:"",
  },
]

export const TimelineObject = ({item}) => {
  const desktop = useMediaQuery('(min-width:769px)');

  return (
      <TimelineItem>
        <TimelineOppositeContent color="text.primary" sx={{padding:desktop ? "6px 16px 24px 16px" : "6px 8px 24px 8px"}}>
          <Typography className={"timeline-hour"}>{item.hour}</Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot sx={{backgroundColor:"#fff"}}/>
          <TimelineConnector sx={{backgroundColor:"#fff"}}/>
        </TimelineSeparator>
        <TimelineContent sx={{padding:desktop ? "6px 16px 24px 16px" : "6px 8px 24px 8px"}}>
          <Typography color={"text.primary"} fontWeight={600}>{item.title}</Typography>
          <Typography color={"text.accent"} fontStyle={"italic"}>{item.place}</Typography>
          {item.address && <Typography color={"text.accent"} fontStyle={"italic"}>{item.address}</Typography>}
        </TimelineContent>
      </TimelineItem>
  )
}