import React, {useState} from "react";
import {WeddingPageStyle} from "./style";
import Helmet from "react-helmet";
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Grid,
  List,
  ListSubheader,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import {Alert, Timeline,} from "@mui/lab";
import {FilterVintage, Tonality} from '@mui/icons-material';
import {contact, ContactCard} from "./molecules/contact/contact";
import {timeLineList, TimelineObject} from "./molecules/timeline/timeline";
import {HotelRoom} from "./organisms/location/location";
import {ImageGrid} from "./molecules/imageGrid/imageGrid";
import {AttendanceForm} from "./organisms/form/form";
import {navigate} from "gatsby";

export const Wedding = () => {
  const desktop = useMediaQuery('(min-width:769px)');
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false)

  return (
      <>
        <Helmet htmlAttributes={{lang: "fr"}}>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, shrink-to-fit=no, viewport-fit=cover"/>
          <title>Le Mariage</title>
        </Helmet>
        <WeddingPageStyle id={"scrollable-area"}>
           <Grid container display={"flex"} rowSpacing={4} justifyContent={"space-around"} alignItems={"flex-start"} sx={{padding:0, margin:0}}>
             <Grid item xs={12} mt={4}>
               <Typography variant={"h1"} color={"text.primary"} textAlign={"center"} className={"wedding-title"} pt={4}>Notre mariage</Typography>
             </Grid>
             <Grid item xs={12} mb={4}>
               <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>
                 <Typography color={"text.primary"} textAlign={"center"} className={"wedding-title"} fontSize={{xs:42, md:56}} lineHeight={{xs:"36px", md:"48px"}}>08/</Typography>
                 <Typography color={"text.primary"} textAlign={"center"} className={"wedding-title"} fontSize={{xs:42, md:56}} lineHeight={{xs:"36px", md:"48px"}}>07/</Typography>
                 <Typography color={"text.primary"} textAlign={"center"} className={"wedding-title"} fontSize={{xs:42, md:56}} lineHeight={{xs:"36px", md:"48px"}}>22</Typography>
               </Stack>
             </Grid>
             <Grid item xs={12} sx={{pb:8}}>
               <Grid container rowSpacing={2}  alignItems={"flex-start"} justifyContent={"center"}>
                 <Grid item xs={12} md={12} sx={{pb:4, pr:1, pl:1, mb:8}}>
                   <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
                     <Typography variant={"h2"} color={"primary"} textAlign={"center"}>Un grand merci à tous pour votre présence !</Typography>
                     <Typography color={"primary"} textAlign={"center"}>Les photos sont maintenant disponible</Typography>
                     <Button onClick={() => navigate("/galerie")} variant={"contained"} sx={{width:300, margin:"auto"}}>Voir les photos</Button>
                   </Stack>
                   {/*<Collapse in={!isSubmitSuccessful}>
                     <Typography color={"primary"} textAlign={"center"}  width={"90%"} sx={{padding: "0 10px", marginLeft:"auto", marginRight:"auto"}} mt={1}  fontStyle={"italic"}>
                       Merci de renseigner votre présence d'ici le 30.04.2022
                     </Typography>
                   </Collapse>
                   <Collapse in={isSubmitSuccessful}>
                     <Alert severity="success" sx={{ margin:"auto", mt:1, mb: 2, maxWidth:"85%"}}>Un grand merci! Votre inscription a été enregistrée.</Alert>
                   </Collapse>
                   <AttendanceForm isSubmitSuccessful={isSubmitSuccessful} setIsSubmitSuccessful={setIsSubmitSuccessful}/>*/}
                 </Grid>
                 <Grid item xs={12} md={12}>
                   <Typography variant={"h2"} color={"primary"} textAlign={"center"}>Programme</Typography>
                   <Timeline position="alternate">
                     {timeLineList.map((item, index) => {
                       return <TimelineObject item={item} key={index}/>
                     })}
                   </Timeline>
                 </Grid>
                 <Grid item xs={12} md={12}>
                   <Stack>
                     <Typography variant={"h2"} color={"primary"} textAlign={"center"}>Infos complémentaires</Typography>
                     <Grid container display={"flex"} rowSpacing={8} justifyContent={"center"} alignItems={"flex-start"} sx={{padding:0, margin:0}}>
                       <Grid item xs={12} md={6} maxWidth={desktop ? "500px !important" : "100%"}>
                         <Stack direction={"row"} spacing={1} justifyContent={"center"} mb={2}>
                           <Typography color={"primary"} textAlign={"center"} fontStyle={"italic"} lineHeight={"32px"}>
                             Dress code:
                           </Typography>
                           <Chip color={"primary"} icon={<Tonality />} label="Pastel" variant="outlined" />
                           <Chip color={"primary"} icon={<FilterVintage />} label="Champêtre" variant="outlined" />
                         </Stack>
                         <Card sx={{ width: 400, minHeight:200, maxWidth:"90%", margin:"auto", overflow:"hidden"}} raised={true}>
                           <ImageGrid/>
                         </Card>
                       </Grid>
                       <Grid item xs={12} md={6} maxWidth={desktop ? "500px !important" : "100%"}>
                         <HotelRoom/>
                       </Grid>
                     </Grid>
                   </Stack>
                 </Grid>
               </Grid>
             </Grid>
             <Grid item xs={12} bgcolor={"primary.main"}>
               <Grid container rowSpacing={2} display={"flex"} alignItems={"flex-start"} justifyContent={"center"}>
                 <Typography pt={2} variant={"h2"} color={"text.secondary"} textAlign={"center"} width={"100%"}>Animations & Questions</Typography>
                 <Typography color={"text.secondary"} textAlign={"center"} mt={1} mb={2} width={"100%"} sx={{padding:"0 10px"}} fontStyle={"italic"}>
                   Pour toute proposition d'animation durant la journée et/ou la soirée, merci de contacter nos animateurs ou nos témoins
                 </Typography>
                 <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} justifyContent={"center"} maxWidth={desktop ? "500px !important" : "100%"}>
                   <List
                       component={"div"}
                       sx={{ width: 425, maxWidth: "90%", bgcolor: 'background.paper', borderRadius:"0 10px 0 10px", pr:2}}
                       subheader={
                         <ListSubheader component="div" color={"primary"}>
                           Animateurs
                         </ListSubheader>
                       }
                   >
                     {contact.map((person, index) => {
                       if(person.type === "entertainer"){
                         return <ContactCard person={person} key={person.name}/>
                       } else return <React.Fragment key={index} />
                     })}
                   </List>
                 </Grid>
                 <Grid item xs={12} md={6} display={"flex"} alignItems={"center"} justifyContent={"center"} maxWidth={desktop ? "500px !important" : "100%"}>
                   <List
                       component={"div"}
                       sx={{ width: 425, maxWidth: "90%", bgcolor: 'background.paper', borderRadius: "0 10px 0 10px", pr:2}}
                       subheader={
                         <ListSubheader component="div" color={"primary"}>
                           Témoins
                         </ListSubheader>
                       }
                   >
                     {contact.map((person, index) => {
                       if(person.type === "witness"){
                         return <ContactCard person={person} key={person.name}/>
                       } else return <React.Fragment key={index}/>
                     })}
                   </List>
                 </Grid>
               </Grid>
               <Box height={40}/>
             </Grid>
           </Grid>

        </WeddingPageStyle>
      </>
  );
}


