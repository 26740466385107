import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery
} from "@mui/material";
import {MaterialTextField} from "../../molecules/materialTextField/materialTextField";
import {AlertDialog} from "../../molecules/alertDialog/alertDialog";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

function encode(data) {
  return Object.keys(data)
      .map(
          (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
}

export const AttendanceForm = ({isSubmitSuccessful, setIsSubmitSuccessful}) => {
  const { handleSubmit, control, getValues, formState } = useForm();
  const desktop = useMediaQuery('(min-width:769px)');
  const [people, setPeople] = useState("single");
  const [peopleNumber, setPeopleNumber] = useState([...Array(1)]);
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [event, setEvent] = useState({
    civil: false,
    aperitif: false,
    repas: false,
    absent: false,
  });

  const { civil, aperitif, repas, absent } = event;

  useEffect(() => {
    if(people === "single"){
      setPeopleNumber([...Array(1)])
    }
  }, [people])

  useEffect(() => {
    if(formState.isSubmitSuccessful !== isSubmitSuccessful) {
      setIsSubmitSuccessful(formState.isSubmitSuccessful)
    }
  }, [formState, isSubmitSuccessful, setIsSubmitSuccessful])

  const handleCheckbox = (e) => {
    error && setError(false)
    if(e.target.name !== "absent" && absent){
      setEvent({
        ...event,
        absent: false,
        [e.target.name]: e.target.checked,
      });
      return
    }
    if([civil, aperitif, repas].filter((v) => v).length > 0 && e.target.name === "absent" && e.target.checked){
      setEvent({
        civil: false,
        aperitif: false,
        repas: false,
        [e.target.name]: e.target.checked,
      });
      return
    }
    setEvent({
      ...event,
      [e.target.name]: e.target.checked,
    });
  };

  const getFormValues = () => {
    const values = {
      info: getValues(),
      presence: event
    }
    return values
    //setOpen(true);
  };

  const handleClickOpen = () => {
    if(!civil && !absent && !repas && !aperitif){
      setError(true)
      return
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    const scrollDiv = document.getElementById("scrollable-area")
    if(scrollDiv){
      scrollDiv.scrollTo(0, 0, )
    }
  };


  const registerForm = async (data) => {
    return new Promise((resolve, reject)=> {
      if(!civil && !absent && !repas && !aperitif){
        setError(true)
        handleClose()
        return
      }
      const formData = new FormData()
      formData.append("form-name", "attendees");
      for ( let key in data ) {
        formData.append(key, data[key]);
      }
      formData.append("nb-attendees", peopleNumber.length);
      for ( let key in event ) {
        formData.append(key, event[key]);
      }
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
          .then(() => {
            resolve(true)
          })
          .catch((error) => {
            console.error(error)
            reject(error)
          });
    })

  };

  return (
      <Stack alignItems={"center"} spacing={2} sx={{pb: desktop ? 0 : 2}}>
        <form
            data-netlify="true"
            name="attendees"
            method="post"
        >
          <Stack alignItems={"center"} spacing={2}>
            <input type="hidden" name="form-name" value="attendees" />
            <input type="hidden" name="firstName" value="" />
            <input type="hidden" name="lastName" value="" />
            <input type="hidden" name="firstName-1" value="" />
            <input type="hidden" name="lastName-1" value="" />
            <input type="hidden" name="firstName-2" value="" />
            <input type="hidden" name="lastName-2" value="" />
            <input type="hidden" name="firstName-3" value="" />
            <input type="hidden" name="lastName-3" value="" />
            <input type="hidden" name="firstName-4" value="" />
            <input type="hidden" name="lastName-4" value="" />
            <input type="hidden" name="firstName-5" value="" />
            <input type="hidden" name="lastName-5" value="" />
            <input type="hidden" name="firstName-6" value="" />
            <input type="hidden" name="lastName-6" value="" />
            <input type="hidden" name="firstName-7" value="" />
            <input type="hidden" name="lastName-7" value="" />
            <input type="hidden" name="firstName-8" value="" />
            <input type="hidden" name="lastName-8" value="" />
            <input type="hidden" name="firstName-9" value="" />
            <input type="hidden" name="lastName-9" value="" />
            <input type="hidden" name="nb-attendees" value="1" />
            <Typography color={"text.accent"} textAlign={"center"}  width={"90%"} sx={{padding: "0 10px", marginLeft:"auto", marginRight:"auto"}} mb={2} fontStyle={"italic"}>
              Combien de personnes figurent sur l'invitation que vous avez reçue ?
            </Typography>
            <ToggleButtonGroup
                color="primary"
                value={people}
                exclusive
                onChange={(event, type) => {
                  setPeople(type);
                }}
            >
              <ToggleButton value="single">Une seule</ToggleButton>
              <ToggleButton value="multiple">Plusieurs</ToggleButton>
            </ToggleButtonGroup>
            {peopleNumber.map((person, index) => {
              return (
                  <Stack
                      key={index} direction={"row"}
                      flexWrap={"wrap"}
                      justifyContent={"space-evenly"}
                      sx={{width:"100%"}}
                  >
                    <MaterialTextField
                        control={control}
                        name={`firstName${index > 0 ? `-${index}` : ""}`}
                        label={index > 0 ? `Prénom invité #${index + 1}` : "Prénom"}
                        autoComplete={"given-name"}
                        rules={{ required: 'Veuillez entrer votre prénom', minLength: 3, maxLength: 24 }}
                        materialOptions={{
                          sx: {marginBottom: 1},
                          variant: "filled"
                        }}
                    />
                    <MaterialTextField
                        control={control}
                        name={`lastName${index > 0 ? `-${index}` : ""}`}
                        label={index > 0 ? `Nom invité #${index + 1}` : "Nom"}
                        autoComplete={"family-name"}
                        rules={{ required: 'Veuillez entrer votre nom', minLength: 3, maxLength: 24 }}
                        materialOptions={{
                          sx: {marginBottom: 1},
                          variant: "filled"
                        }}
                    />
                  </Stack>
              )
            })}
            <Collapse in={people === "multiple"} sx={{pl:1, pr:1}}>
              <Stack direction={"row"} justifyContent={"center"} spacing={2}>
                {peopleNumber.length > 1 &&
                    <Button
                        size="small"
                        variant={"outlined"}
                        onClick={() => setPeopleNumber([...Array(peopleNumber.length - 1)])}
                    >
                      Retirer
                    </Button>
                }
                <Button
                    disabled={peopleNumber.length > 9}
                    size="small"
                    variant={peopleNumber.length > 1 ? "contained" : "outlined"}
                    onClick={() => setPeopleNumber([...Array(peopleNumber.length + 1)])}
                >
                  Ajouter{peopleNumber.length === 1 ? " une personne" : ""}
                </Button>
              </Stack>
              <Typography color={"primary"} textAlign={"center"}  width={"90%"} sx={{padding: "10px", marginLeft:"auto", marginRight:"auto"}} mb={2} fontStyle={"italic"}>
                Ne mentionnez que les personnes présentes
              </Typography>
            </Collapse>
            <FormGroup>
              {error && <Typography color={"text.error"}>Veuillez cocher au moins une réponse</Typography>}
              <FormControlLabel
                  control={<Checkbox sx={{color:"primary.main"}} color={"accent"} checked={event.civil} onChange={handleCheckbox} name="civil" />}
                  label={<Typography color={"text.accent"}>{peopleNumber.length > 1 ? "Nous serons présents":"Je serai présent·e"} au mariage civil</Typography>}
              />
              <FormControlLabel
                  control={<Checkbox sx={{color:"primary.main"}} color={"accent"} checked={event.aperitif} onChange={handleCheckbox} name="aperitif" />}
                  label={<Typography color={"text.accent"}>{peopleNumber.length > 1 ? "Nous serons présents":"Je serai présent·e"} pour l'apéritif</Typography>}
              />
              <FormControlLabel
                  control={<Checkbox sx={{color:"primary.main"}} color={"accent"} checked={event.repas} onChange={handleCheckbox} name="repas" />}
                  label={<Typography color={"text.accent"}>{peopleNumber.length > 1 ? "Nous serons présents":"Je serai présent·e"} pour le repas</Typography>}
              />
              <FormControlLabel
                  control={<Checkbox sx={{color:"primary.main"}} color={"accent"} checked={event.absent} onChange={handleCheckbox} name="absent" />}
                  label={<Typography color={"text.accent"}>{peopleNumber.length > 1 ? "Nous serons absents":"Je serai absent·e"}</Typography>}
              />
            </FormGroup>
            <Button
                color={"primary"}
                variant={"contained"}
                onClick={handleClickOpen}
                disabled={formState.isSubmitSuccessful}
            >
              Envoyer
            </Button>
            <AlertDialog
                open={open}
                peopleNumber={peopleNumber}
                handleClose={handleClose}
                handleConfirm={handleSubmit(registerForm)}
                getValues={getFormValues}
            />
          </Stack>
        </form>
      </Stack>
  )
}