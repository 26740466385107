import React, {useState} from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import {TabPanel} from "../../molecules/tabPanel/tabPanel";
import GoogleMapContainer from "../../molecules/map/map";
import {AssistantDirection, ExpandMore, LocalParking, Map} from "@mui/icons-material";


const parkingPlaces = [
  {
    location: "Pl. de l'Hôtel-de-Ville 3, 1096 Cully",
    link: "https://goo.gl/maps/MYF488ZomDkeadzQ9",
    lot: "(env. 9 places)"
  },
  {
    location: "Pl. d'Armes 16, 1096 Cully",
    link: "https://goo.gl/maps/h5SQU5441GboC7hM9",
    lot: "(env. 7 places)"
  },
  {
    location: "Rte de Lausanne 3, 1096 Cully",
    link: "https://goo.gl/maps/6zZL2DyrQoA1M5fd8",
    lot: "(env. 8 places)"
  },
  {
    location: "Rte de Lausanne 2, 1096 Cully",
    link: "https://goo.gl/maps/fGUTGdDzUmnU1iBe8",
    lot: "(env. 8 places)"
  },
  {
    location: "Rue du Temple 17, 1096 Cully",
    link: "https://goo.gl/maps/wyr2WdCyixBfmTJN7",
    lot: "(env. 22 places)"
  },
  {
    location: "Chem. de Versailles 11, 1096 Cully",
    link: "https://goo.gl/maps/1WqLL4gybn2NHw717",
    lot: "(env. 7 places)"
  },
  {
    location: "Pl. d'Armes 1, 1096 Cully",
    link: "https://goo.gl/maps/6zZL2DyrQoA1M5fd8",
    lot: "(env. 28 places)"
  },
  {
    location: "Chemin du Vigny, 1096 Cully",
    link: "https://goo.gl/maps/y1kEU3nMJXKJM3759",
    lot: "(env. 14 places)"
  },
]

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function HotelRoom() {
  const [value, setValue] = useState(0);
  const [expanded, setExpanded] = useState("map");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs textColor="primary" indicatorColor="primary" value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab sx={{color:"#C0B0B0FF"}} label="Maison Jaune de Cully" {...a11yProps(0)} />
            <Tab sx={{color:"#C0B0B0FF"}} label="Cortège" {...a11yProps(1)} />
            <Tab sx={{color:"#C0B0B0FF"}} label="Hôtel du Léman" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Accordion expanded={expanded === "map"} onClick={() => setExpanded(prevState => prevState === "map" ? null : "map")}>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
                <Map color="white" fontSize={"small"} sx={{backgroundColor:"primary.main", borderRadius:"100px", padding:"4px"}}/>
                <Typography color={"primary"} variant={"h5"}>Mariage Civil</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <GoogleMapContainer streetView lat={46.4881027} lng={6.7304491} heading={-100} pitch={10}/>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expanded === "parking"} onClick={() => setExpanded(prevState => prevState === "parking" ? null : "parking")}>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"}>
                <LocalParking color="white" fontSize={"small"} sx={{backgroundColor:"primary.main", borderRadius:"100px", padding:"4px"}}/>
                <Typography color={"primary"} variant={"h5"}>Parkings</Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <List sx={{bgcolor: 'background.paper', borderRadius:"10px 0 10px 0"}}>
                {parkingPlaces.map((place, index) => {
                  return (
                      <ListItem
                          key={index}
                          secondaryAction={
                            <IconButton onClick={()=> window.open(place.link, "_blank")}>
                              <AssistantDirection sx={{fontSize: 28, right:0}}/>
                            </IconButton>
                          }
                      >
                        <ListItemText
                            primary={place.location}
                            primaryTypographyProps={{
                              color: "accent.main"
                            }}
                            secondary={
                              <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                              >
                                {place.lot}
                              </Typography>
                            }
                        />
                      </ListItem>
                  )
                })}
              </List>
            </AccordionDetails>
          </Accordion>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GoogleMapContainer
              lat={46.4881027}
              lng={6.7304491}
              direction
              origin={{lat: 46.4881027, lng: 6.7304491}}//ChIJbRQ4DpQojEcRH7Iex4LPVVs
              destination={{lat: 46.477920, lng: 6.842730}}//ChIJX6uffSaDjkcRKw4GWdu8ALs
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <GoogleMapContainer streetView lat={46.4774625} lng={6.8422092} heading={25}/>
          <Stack direction={"row"} spacing={1} display={"flex"} alignItems={"center"} mt={2} width={"100%"}>
            <LocalParking color="white" fontSize={"small"} sx={{backgroundColor:"primary.main", borderRadius:"100px", padding:"4px"}}/>
            <Typography color={"primary"} variant={"h5"}>Parking de l'hotel</Typography>
            <Box ml={4}>
              <IconButton onClick={()=> window.open("https://goo.gl/maps/UQZfuAc2tCihmhWp9", "_blank")}>
                <AssistantDirection sx={{fontSize: 28, right:0}}/>
              </IconButton>
            </Box>
          </Stack>
        </TabPanel>
      </Box>
  );
}
